import React from 'react';

import { Link, graphql } from 'gatsby';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import FlodeskModal from '../../components/Flodesk/SignupModal';
import '../../scss/partnerCompany.scss';

function PartnerCompany({ data }) {
  const companyInfo = data.contentfulPartnerCompany;
  const description =
    companyInfo.founder.description.childrenMarkdownRemark[0].html;
  const instagramInfo = find(companyInfo.founder.social, ['type', 'instagram']);
  const twitterInfo = find(companyInfo.founder.social, ['type', 'twitter']);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="partnerCompany">
      <div className="partnerCompany__container">
        <aside>
          <ul className="d-flex flex-lg-column flex-row align-items-center">
            <Link to="/perks">
              <li className="partnerCompany__back d-flex justify-content-center align-items-center text-center">
                <img
                  src="../images/arrow-left.svg"
                  alt="return to previous page"
                />
              </li>
            </Link>
          </ul>
        </aside>
        <div className="partnerCompany__body">
          <div className="partnerCompany__head d-flex flex-column justify-content-center align-items-center">
            <h3>{companyInfo.name}</h3>
            <Image src={companyInfo.image.url} alt={companyInfo.image.title} />
          </div>

          <div className="d-flex flex-column flex-xl-row align-items-xl-start align-items-center">
            <div className="d-flex flex-column align-items-center partnerCompany__bodyPerson">
              <Image
                src={companyInfo.founder.founderImage.url}
                alt={companyInfo.founder.founderImage.title}
              />
              <div>
                {companyInfo.founder.name}
                <br />
                <span>{companyInfo.founder.title}</span>
              </div>
            </div>
            <div>
              <div
                className="partnerCompany__bodyPersonDetails"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {!isEmpty(instagramInfo) && (
                <a
                  href={instagramInfo.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    src="/images/instagram.svg"
                    alt="instagram"
                    className="social-link"
                  />
                </a>
              )}
              {!isEmpty(twitterInfo) && (
                <a
                  href={twitterInfo.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ paddingLeft: '0.5rem' }}
                >
                  <Image
                    src="/images/twitter.svg"
                    alt="twitter"
                    className="social-link"
                  />
                </a>
              )}
              <div className="signup__link">
                <Link className="" to="#" onClick={() => setModalShow(true)}>
                  Sign up to unlock our exclusive member discount!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

PartnerCompany.propTypes = {
  data: PropTypes.object
};

export const data = graphql`
  query($slug: String) {
    contentfulPartnerCompany(slug: { eq: $slug }) {
      category
      name
      image {
        title
        description
        url
      }
      founder {
        slug
        name
        title
        social {
          display
          url
          type
        }
        description {
          childrenMarkdownRemark {
            html
          }
        }
        founderImage {
          description
          title
          url
        }
      }
    }
  }
`;

export default PartnerCompany;
